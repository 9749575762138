/* eslint-disable no-new */
import { bindGtmEventsForPlyrPlayer } from '@/utils/Controllers/GTM/Operations/Video';
import EVENTS from '@/utils/Constants/Events';
import SmarteditController from '@/utils/Controllers/SmarteditController';
import { LazyPlyrWhenVisible } from '@/utils/Controllers/LazyPlyrController';

class Player {
  constructor(el, Plyr) {
    this.Plyr = Plyr;
    this.DOM = { el };
    this.DOM.videoContainer = this.DOM.el.querySelector(
      '.ken-video__content__player',
    );
    this.DOM.videoPlayer = this.DOM.videoContainer.querySelector('.js-player');
    this.DOM.playButton = this.DOM.el.querySelector(
      '.ken-video__content__link',
    );
    this.DOM.closeButton = this.DOM.videoContainer.querySelector(
      '.ken-video__content__button-close',
    );

    this.initEvents();
  }

  initEvents() {
    const self = this;

    const plyrController = new this.Plyr(self.DOM.videoPlayer);
    const { videoId, videoTitle, videoUrl } = plyrController.config;
    const gtmInfo = { videoId, videoTitle, videoUrl };
    bindGtmEventsForPlyrPlayer(plyrController, gtmInfo);

    plyrController.on('ready', () => {
      self.DOM.el.classList.add('ready');
    });

    plyrController.on('play', () => {
      DLG.EVE.emit(EVENTS.VIDEO.PLAY, plyrController.id || null);
    });

    self.DOM.playButton.addEventListener('click', event => {
      event.preventDefault();
      self.DOM.videoContainer.classList.add('visible');
      plyrController.play();
    });

    self.DOM.closeButton.addEventListener('click', event => {
      event.preventDefault();
      self.DOM.videoContainer.classList.remove('visible');
      plyrController.stop();
    });

    const pauseMeIfAnotherVideoIsPlaying = id => {
      if (id !== plyrController.id) {
        plyrController.pause();
      }
    };

    DLG.EVE.on(EVENTS.VIDEO.PLAY, pauseMeIfAnotherVideoIsPlaying);
  }
}

const init = async () => {
  const videos = SmarteditController.getEditorialEntries('.js-ken-video');
  if (videos.length > 0) {
    LazyPlyrWhenVisible(videos, (target, PlyrLib) => {
      new Player(target, PlyrLib);
    });
  }
};

export default async () => {
  SmarteditController.addOnReprocessPageListener(init);
  init();
};
